import {
  SET_MODAL_CONFIRM_ADJUST_LEVERAGE,
  SET_MODAL_LEVERAGE_OPEN,
  SET_MODAL_SELECT_PAIR,
  SET_MODAL_TERMS,
} from '../constants/modal';

interface ModalState {
  modalSelectPair: boolean;
  modalTerms: boolean;
  modalConfirmAdjustLeverage: boolean;
  leverageModalOpen: boolean;
}

const initialState: ModalState = {
  modalSelectPair: false,
  modalTerms: false,
  modalConfirmAdjustLeverage: false,
  leverageModalOpen: false,
};

export const modalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_MODAL_SELECT_PAIR:
      return {
        ...state,
        modalSelectPair: action.payload,
      };
    case SET_MODAL_TERMS:
      return {
        ...state,
        modalTerms: action.payload,
      };
    case SET_MODAL_CONFIRM_ADJUST_LEVERAGE:
      return {
        ...state,
        modalConfirmAdjustLeverage: action.payload,
      };
    case SET_MODAL_LEVERAGE_OPEN:
      return {
        ...state,
        leverageModalOpen: action.payload,
      };
    default:
      return state;
  }
};
