import loadable, { DefaultComponent } from '@loadable/component';
import { Spin } from 'antd';
import React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router';
import { PATHS } from 'src/constants/paths';
import { PrivateRoute } from './components/PrivateRoute';
// import LiquidityPool from '../pages/liquidity-pool';
// import Portfolio from '../pages/portfolio/components/Portfolio';

function loadableWFallback(loadFn: (props: {}) => Promise<DefaultComponent<{}>>) {
  return loadable(loadFn, {
    fallback: (
      <div className="flex items-center justify-center h-screen">
        <Spin />
      </div>
    ),
  });
}
const Exchange = loadableWFallback(() => import('src/pages/exchange'));
const Portfolio = loadableWFallback(() => import('src/pages/portfolio'));
// const Orders = loadableWFallback(() => import('src/pages/orders'));
const LiquidityPool = loadableWFallback(() => import('src/pages/liquidity-pool'));
// const Referrals = loadableWFallback(() => import('src/pages/referrals'));
// const Swap = loadableWFallback(() => import('src/pages/swap'));
// const Points = loadableWFallback(() => import('src/pages/points'));
// const MarketApi = loadableWFallback(() => import('src/pages/market-api'));
const NotFound = loadableWFallback(() => import('./components/NotFound'));
const AdminLayout = loadableWFallback(() => import('src/pages/admin'));

const LayoutAdminRouter: React.FC<RouteProps> = (props) => {
  return <PrivateRoute {...props} />;
};

const Routes: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const referralCode = searchParams.get('referral');

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={PATHS.trade(referralCode ? `referral=${referralCode}` : '')} />}
      />
      <LayoutAdminRouter exact path={`${PATHS.admin()}`} component={AdminLayout} />
      <Route exact path={`${PATHS.trade()}/:pair?`} component={Exchange} />
      <Route exact path={`${PATHS.portfolio()}`} component={Portfolio} />
      <Route exact path={`${PATHS.liquidityPool()}`} component={LiquidityPool} />
      <Route exact path="/:referralCode" component={Exchange} />
      {/* <Route exact path={`${PATHS.orders()}`} component={Orders} />
      <Route exact path={`${PATHS.referrals()}`} component={Referrals} />
      <Route exact path={`${PATHS.swap()}`} component={Swap} />
      <Route exact path={`${PATHS.points()}`} component={Points} />
      <Route exact path={`${PATHS.api()}`} component={MarketApi} /> */}
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
