import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'src/libs/axios';
import { useAccount } from 'wagmi';
import { userKeys } from './user-keys';
export type CheckRefCodeResponse = {
  status: boolean;
  message: string;
};

export type CheckRefCodeParams = {
  referral_code: string;
};

export const checkRefCode = async (refCode: string) => {
  const response = await apiClient.post(`/v1/public/check-ref-code`, {
    referral_code: refCode,
  });
  return response.data;
};

export type UseCheckRefCodeOptions<TError = Error, TContext = unknown> = MutationOptions<
  CheckRefCodeResponse,
  TError,
  CheckRefCodeParams,
  unknown
>;

export const useCheckRefCode = <TError = Error, TContext = unknown>(
  options?: UseCheckRefCodeOptions<TError, TContext>,
) => {
  return useMutation({
    mutationFn: (params: CheckRefCodeParams) => checkRefCode(params.referral_code),
    ...options,
  });
};
