import { Layout } from 'antd';
import { toArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppHeader from 'src/components/01.layout/header/AppHeader';
import { getStorageUserTheme } from 'src/helpers/storage';
import { useDisclosure } from 'src/hooks/useDisclosure';
import useWindowSize from 'src/hooks/useWindowSize';
import { THEME_MODE } from 'src/interfaces/theme';
import { useGetPosition } from 'src/services/order/get-position';
import { setTheme } from 'src/store/actions/theme';
import { AccessCodeModal } from './header/AccessCodeModal';
import MobileMenuDetail from './header/MobileMenuDetail';
import './styles.scss';

const { Content } = Layout;

export const formatDate = 'DD-MM-YYYY HH:mm:ss';

interface ILayoutProps {
  children?: React.ReactNode;
}

export enum ThemesMode {
  dark = 'dark',
  light = 'light',
}

const LayoutDefault: React.FC<ILayoutProps> = ({ children }) => {
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);
  const [isOpenNotificationMobile, setIsOpenNotificationMobile] = useState(false);
  const userTheme = useSelector((state: any) => state.theme);
  const { isDesktop, width } = useWindowSize();
  const [openAccessCodeModal, { open: openAccessModal, close: closeAccessModal }] = useDisclosure();
  const dispatch = useDispatch();

  const userPostionQuery = useGetPosition({
    select: toArray,
  });

  useEffect(() => {
    const accessCode = localStorage.getItem('accesscode');
    if (!getStorageUserTheme()) {
      dispatch(setTheme(THEME_MODE.DARK));
      document.documentElement.setAttribute('data-theme', THEME_MODE.DARK.toLocaleLowerCase());
    }
    if (!getStorageUserTheme()) {
      dispatch(setTheme(THEME_MODE.DARK));
      document.documentElement.setAttribute('data-theme', THEME_MODE.DARK.toLocaleLowerCase());
    }
    window.addEventListener('storage', (e) => {
      if (
        getStorageUserTheme() &&
        getStorageUserTheme()?.toLocaleLowerCase() !== userTheme.toLocaleLowerCase()
      ) {
        if (getStorageUserTheme() === THEME_MODE.DARK?.toLocaleLowerCase()) {
          dispatch(setTheme(THEME_MODE.DARK));
          document.documentElement.setAttribute('data-theme', THEME_MODE.DARK.toLocaleLowerCase());
        } else {
          dispatch(setTheme(THEME_MODE.LIGHT));
          document.documentElement.setAttribute('data-theme', THEME_MODE.LIGHT.toLocaleLowerCase());
        }
      }
    });
    if (!accessCode) {
      openAccessModal();
    }
    return () => {
      window.removeEventListener('storage', (e) => {});
    };
  }, []);

  const handleOpenMenuMobile = (bool: boolean) => {
    setIsOpenMenuMobile(bool);
    setIsOpenNotificationMobile(false);
  };

  const handleOpenNotificationMobile = (bool: boolean) => {
    setIsOpenMenuMobile(false);
    setIsOpenNotificationMobile(bool);
  };

  useEffect(() => {
    if (width > 1024) setIsOpenMenuMobile(false);
  }, [width]);

  return (
    <div id="layout" className="m-0">
      <Layout className=" font-montserrat ">
        <AccessCodeModal
          open={openAccessCodeModal}
          onOk={openAccessModal}
          onClose={closeAccessModal}
        />
        <AppHeader
          isOpenMenuMobile={isOpenMenuMobile}
          setIsOpenMenuMobile={handleOpenMenuMobile}
          userPositionData={userPostionQuery.data}
          // isOpenNotificationMobile={isOpenNotificationMobile}
          // setIsOpenNotificationMobile={handleOpenNotificationMobile}
          // getCountUnRead={() => {}}
          // notificationCount={0}
        />
        <Layout className={`site-layout ${userTheme}  `}>
          <Content className="site-layout-background  overflow-y-hidden">
            <div className={width <= 1024 && isOpenMenuMobile ? '' : 'display-none'}>
              <MobileMenuDetail
                address={''}
                onClickItem={() => setIsOpenMenuMobile(false)}
                isOpen={width <= 1024 && isOpenMenuMobile}
              />
            </div>

            <div
              className={
                isDesktop || (!isOpenMenuMobile && !isOpenNotificationMobile) ? '' : 'display-none'
              }
            >
              {children}
            </div>
          </Content>
        </Layout>
        {/* <Footer /> */}
      </Layout>
    </div>
  );
};

export default LayoutDefault;
