import { notification } from 'antd';
import { IS_STABLE_COIN } from 'src/constants/exchange';
import { UserPositionDto } from '../services/order/get-position';
import BigNumber from 'bignumber.js';

export const ellipseAddress = (
  address = '',
  maxCharacters = 5,
  maxLastCharacters?: number | undefined,
): string => {
  if (!address) return '';

  return `${address.slice(0, maxCharacters)}...${address.slice(
    -(maxLastCharacters ? maxLastCharacters : maxCharacters),
  )}`;
};

export const decimalCount = (number: any) => {
  const numberAsString = number?.toString();
  if (numberAsString?.includes('.')) {
    return numberAsString?.split('.')?.[1]?.length;
  }
  return 0;
};

export const isStableCoin = (isFNFTOrCoin: any) => {
  return isFNFTOrCoin === IS_STABLE_COIN;
  // return coin?.toUpperCase().includes(STABLE_COINS.USD || STABLE_COINS.USDT || STABLE_COINS.BUSD);
};

export const delay = (timer: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, timer);
  });

export const calculateTotalAmount = (objects: any): number => {
  if (objects.length < 1) {
    return 0;
  }
  const amount = objects.reduce(
    (total: number, obj: any) => total + obj.amount * obj.current_price,
    0,
  );
  return amount;
};

export const calculateTotalPNL = (objects: UserPositionDto[]): number => {
  if (objects.length < 1) {
    return 0;
  }
  const pnl = objects.reduce((total: number, obj: UserPositionDto) => {
    let difference;
    if (obj.direction === 'buy') {
      difference = new BigNumber(obj.current_price || 0).minus(obj.entry_price || 0).toNumber();
    } else {
      difference = new BigNumber(obj.entry_price || 0).minus(obj.current_price || 0).toNumber();
    }
    return total + difference * obj.amount;
  }, 0);
  return pnl;
};

export const formatString = (str: string): string => {
  const startLength = 6;
  const endLength = 6;

  if (str.length <= startLength + endLength) {
    return str;
  }

  const start = str.substring(0, startLength);
  const end = str.substring(str.length - endLength);
  return `${start}...${end}`;
};

export const extractErrorMessage = (log: string): string => {
  // Define a regex pattern to extract the JSON part of the log
  const pattern = /response:\s*(\{.*\})/;

  // Search for the pattern in the log
  const match = log.match(pattern);

  if (match) {
    // Extract the JSON string
    const jsonStr = match[1];
    try {
      // Parse the JSON string
      const response = JSON.parse(jsonStr);
      // Get the error message if available
      const errorMessage = response.error || 'No error message found';
      // Capitalize the first letter of the error message
      return capitalizeFirstLetter(errorMessage);
    } catch (error) {
      return 'Invalid JSON in log';
    }
  } else {
    return 'No JSON response found in log';
  }
};

export const capitalizeFirstLetter = (text: string): string => {
  if (!text) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
};

// export const formatNumber = (value: number, decimalPlaces: number = 4): number => {
//   const multiplier = Math.pow(10, decimalPlaces);
//   return Math.floor(value * multiplier) / multiplier;
// };
export const formatNumber = (value: number, decimalPlaces: number = 4): number => {
  const multiplier = Math.pow(10, decimalPlaces);
  const result = new BigNumber(value)
    .multipliedBy(multiplier)
    .dividedBy(multiplier)
    .decimalPlaces(decimalPlaces, BigNumber.ROUND_DOWN)
    .toNumber();
  return result;
};

export const truncateAddress = (address: string) => {
  if (address && address.length <= 6) return address; // No need to truncate if the address is too short

  const prefix = address.slice(0, 4); // Typically "0x"
  const suffix = address.slice(-4); // The last 4 characters

  return `${prefix}...${suffix}`;
};

export const truncateReferralLink = (referralLink: string) => {
  const prefix = referralLink.slice(0, 15);
  const suffix = referralLink.slice(-19);

  return `${prefix}...${suffix}`;
};

export const handleCopyClick = (data: string, msg: string) => {
  navigator?.clipboard
    .writeText(data as string)
    .then(() => {
      notification.success({
        message: 'Success',
        description: `${capitalizeFirstLetter(msg)} copied!`,
        placement: 'bottomRight',
        style: { fontFamily: 'Montserrat' },
      });
    })
    .catch((err) => {
      console.error('Unable to copy text to clipboard', err);
    });
};
