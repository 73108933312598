import { defineChain } from 'viem';

//Testnet
export const bitLayerTestnet = defineChain({
  id: 200810,
  name: 'Bitlayer Testnet',
  iconUrl: 'https://img.cryptorank.io/coins/bitlayer1708427921502.png',
  iconBackground: '#fff',
  nativeCurrency: { name: 'BitCoin', symbol: 'BTC', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://testnet-rpc.bitlayer.org'] },
  },
  blockExplorers: {
    default: { name: 'BitLayer Testnet', url: 'https://testnet.btrscan.com' },
  },
  testnet: true,
});

export const sepoliaConfig = defineChain({
  id: 11_155_111,
  name: 'Sepolia',
  iconUrl: 'https://img.cryptorank.io/coins/ethereum1524754015525.png',
  nativeCurrency: { name: 'Sepolia Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://eth-sepolia.api.onfinality.io/public'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Etherscan',
      url: 'https://sepolia.etherscan.io',
      apiUrl: 'https://api-sepolia.etherscan.io/api',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 751532,
    },
    ensRegistry: { address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e' },
    ensUniversalResolver: {
      address: '0xc8Af999e38273D658BE1b921b88A9Ddf005769cC',
      blockCreated: 5_317_080,
    },
  },
  testnet: true,
});

export const seiDevnet = defineChain({
  id: 713_715,
  name: 'Sei Devnet',
  iconUrl: 'https://img.cryptorank.io/coins/sei_network1661962346631.png',
  nativeCurrency: { name: 'Sei', symbol: 'SEI', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://evm-rpc-arctic-1.sei-apis.com'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Seitrace',
      url: 'https://seitrace.com',
    },
  },
  testnet: true,
});

//Mainnet
export const bitLayerMainnet = defineChain({
  id: 200901,
  name: 'Bitlayer',
  iconUrl: 'https://img.cryptorank.io/coins/bitlayer1708427921502.png',
  iconBackground: '#fff',
  nativeCurrency: { name: 'BitCoin', symbol: 'BTC', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc.bitlayer.org/'] },
  },
  blockExplorers: {
    default: { name: 'BitLayer', url: 'https://www.btrscan.com/' },
  },
});
export const sei = defineChain({
  id: 1329,
  name: 'Sei',
  iconUrl: 'https://img.cryptorank.io/coins/sei_network1661962346631.png',
  nativeCurrency: { name: 'Sei', symbol: 'SEI', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://evm-rpc.sei-apis.com'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Seitrace',
      url: 'https://seitrace.com',
    },
  },
});
