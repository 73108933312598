const SIGNATURE_LIMIT = 1000;

export function storeSignature(signatureObject: string, walletAddress: string) {
  try {
    const parsedSignature = JSON.parse(signatureObject);

    if (!parsedSignature.chainID) {
      throw new Error('Invalid signature: Missing chainID.');
    }

    const chainId = parsedSignature.chainID;
    const storageKey = `signatures-${walletAddress}-${chainId}`;

    // Get existing signatures or initialize an empty array
    const storedSignatures = JSON.parse(localStorage.getItem(storageKey) || '[]');

    if (!Array.isArray(storedSignatures)) {
      throw new Error('Stored signatures are corrupted.');
    }
    if (storedSignatures.length >= SIGNATURE_LIMIT) {
      storedSignatures.shift(); // Remove the oldest signature
    }

    storedSignatures.push(signatureObject); // Add the new signature

    localStorage.setItem(storageKey, JSON.stringify(storedSignatures));
  } catch (error) {
    console.error('Error storing signature:', error);
  }
}
