import SafeProvider from '@safe-global/safe-apps-react-sdk';
import { ConfigProvider, theme } from 'antd';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import LayoutDefault from './components/01.layout';
import MobileLayout from './components/moblie-layout';
import { useDeviceType } from './hooks/useIsMobile';
import { THEME_MODE } from './interfaces/theme';
import { RainbowProvider } from './libs/rainbow';
import Routes from './routes/Routes';
import store, { useAppSelector } from './store';

const AppRoutes = () => {
  const userTheme = useAppSelector((state: any) => state.theme);

  const darkMode = userTheme === THEME_MODE.DARK;
  const deviceType = useDeviceType();
  return (
    <ConfigProvider
      theme={{
        algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          fontFamily: 'Montserrat',
        },
      }}
    >
      {deviceType === 'Mobile' || deviceType === 'Tablet' ? (
        <MobileLayout />
      ) : (
        <LayoutDefault>
          <Routes />
        </LayoutDefault>
      )}
    </ConfigProvider>
  );
};

function App() {
  return (
    <SafeProvider>
      <Provider store={store}>
        <BrowserRouter>
          <RainbowProvider>
            <AppRoutes />
          </RainbowProvider>
        </BrowserRouter>
      </Provider>
    </SafeProvider>
  );
}

export default App;
