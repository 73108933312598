import { useVerifyToken } from 'src/services/auth/verify';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { PATHS } from 'src/constants/paths';
import { useAccount } from 'wagmi';

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { address: userAddress } = useAccount();
  const verifyTokenQuery = useVerifyToken() as { data?: { role?: string } };
    
  if (!userAddress || (verifyTokenQuery.data && verifyTokenQuery.data?.role !== 'ADMIN')) {
    return (
      <Redirect
        to={{
          pathname: PATHS.trade(),
        }}
      />
    );
  }

  return <Route {...props} />;
};
