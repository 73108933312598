import { SET_MODAL_CONFIRM_ADJUST_LEVERAGE, SET_MODAL_LEVERAGE_OPEN, SET_MODAL_SELECT_PAIR, SET_MODAL_TERMS } from '../constants/modal';

export const setModalSelectPair = (isOpen: boolean) => {
  return {
    type: SET_MODAL_SELECT_PAIR,
    payload: isOpen,
  };
};

export const setModalTerms = (isOpen: boolean) => {
  return {
    type: SET_MODAL_TERMS,
    payload: isOpen,
  };
};

export const setModalConfirmAdjustLeverage = (isOpen: boolean) => {
  return {
    type: SET_MODAL_CONFIRM_ADJUST_LEVERAGE,
    payload: isOpen,
  };
};

export const setModalLeverageOpen = (isOpen: boolean) => {
  return {
    type: SET_MODAL_LEVERAGE_OPEN,
    payload: isOpen,
  };
};

